import React, {useEffect} from 'react';
import { navigate } from 'gatsby';

function QuickRef() {
    useEffect(() => {
        if (process.env.NODE_ENV !== 'development') {
            navigate('/404');
        }
    });

    const routes = [        
        "/",
        "/welcome",
        "/debt",
        "/profile",
        "/profile/edit",
        "/savings",
        "/bank-accounts",
        "/bank-account/:id",
        "/funeral",
        "/funeral/:id",
        "/insurance",
        "/credit-life",
        "/loan/:id",
        "/employer",
        "/employer/add",
        "/savings/add",
        "/savings/add/:bucketType",
        "/savings/:id",
        "/budget",
        "/debt-repayments"
    ];


    return(
        <div>            
            {routes.map((route, i)=><div key={i}><a href={route}>{route}</a></div>)}
        </div>
    );
}

export default QuickRef